<template>
  <div class="content">
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>
    <TheNav :navLinkList="navLinkList" :classifyList="classifyList" />

    <router-view></router-view>
  </div>
</template>

<script>
import { getAboutList,getAboutNavList } from "@/utils/common";

export default {
  name: 'About',
  data() {
    return {
      slides: [
        {
          title: '关于我们',
          img: require('@/assets/about-main.png'),
        },
      ],
      classifyList:getAboutList(),
      navLinkList:getAboutNavList(),
    }
  },
  created() {
    this.navLinkList.push({ name:'关于易宝通',link:''})
  },
  methods: {
  }
}
</script>

<style scoped>
</style>